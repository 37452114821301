import { ArrowSquareUpRight } from '@phosphor-icons/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { sideBarHandler } from '@/components/core/MainHeaderV3';
import menuConfig, { Menu, OUTLINKS, SubMenu } from '@/components/core/MenuConfigV3';

export default function MenuMobileV3({
  isSideBarShow,
  onClickHandler,
}: {
  isSideBarShow: boolean;
  onClickHandler: () => void;
}) {
  const { t } = useTranslation('common');

  useEffect(() => {
    sideBarHandler(!isSideBarShow);
  }, [isSideBarShow]);

  return (
    <div
      id="side-bar"
      className="fixed left-0 z-20 transition-all duration-200 top-[72px] h-[calc(100vh-72px)] w-screen overflow-y-auto bg-gray-900"
      style={{
        opacity: 0,
        pointerEvents: 'none',
      }}
    >
      <div className="flex flex-col p-6 space-y-7">
        {menuConfig.map((menu) =>
          'subMenu' in menu ? (
            <div key={`mobile:menu:${menu.title}`} className="flex flex-col space-y-[10px]">
              <h4 className="text-sm text-gray-400 font-medium">{menu.title}</h4>

              {menu.subMenu!.map((subMenu) => {
                return (
                  <MenuButton
                    key={`mobile:menu:subMenu:${subMenu.title}`}
                    menu={subMenu}
                    isSubMenu
                    onClickHandler={onClickHandler}
                  />
                );
              })}
            </div>
          ) : (
            <MenuButton
              key={`mobile:menu:${menu.title}`}
              menu={menu}
              onClickHandler={onClickHandler}
            />
          ),
        )}

        <hr className="border-gray-700" />

        <div>
          {OUTLINKS.map((link) => (
            <a
              key={`mobile:menu:outlink:${link.name}`}
              className="px-3 py-[10px] font-medium text-[15px] flex items-center space-x-3"
              href={t(link.href)}
              onClick={onClickHandler}
              target="_blank"
              rel="noreferrer"
            >
              <span>{link.name}</span>
              <ArrowSquareUpRight className="w-4 h-4 text-gray-400" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

function MenuButton({
  menu,
  isSubMenu,
  onClickHandler,
}: {
  menu: Menu | SubMenu;
  isSubMenu?: boolean;
  onClickHandler: () => void;
}) {
  const router = useRouter();
  const pathName = router.pathname;

  const { t } = useTranslation('common');
  const inPreparation = 'inPreparation' in menu && !!menu.inPreparation;

  return (
    <Link
      passHref
      href={{
        pathname: t(menu.path ?? ''),
      }}
      prefetch={false}
    >
      <a
        className={clsx(
          'flex flex-row items-center space-x-3 text-sm font-semibold hover:opacity-70 py-3 rounded-lg',
          {
            'px-2': !isSubMenu,
            'px-3': isSubMenu,
            'bg-gray-800 text-white': pathName === menu.path,
            'hover:bg-gray-800': !inPreparation,
          },
        )}
        onClick={(e) => {
          if (inPreparation) return e.preventDefault();

          onClickHandler();
        }}
      >
        {menu.icon}
        <span className="text-[15px]">{menu.title}</span>

        {'suffix' in menu && menu.suffix && (
          <span
            className={clsx(
              'rounded leading-3 px-1 py-0.5 font-semibold text-[9px]',
              menu.suffix.className,
            )}
          >
            {menu.suffix.text}
          </span>
        )}
      </a>
    </Link>
  );
}
