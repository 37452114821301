import clsx from 'clsx';
import { m } from 'framer-motion';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import type { ReactNode } from 'react';

// ----------------------------------------------------------------------

interface PageProps {
  children: ReactNode;
  description?: string;
  title?: string;
  className?: string;
}

const Page = ({ children, description = '', title = '', className, ...other }: PageProps) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const titl = title ? `${title} | ${t('title-suffix')}` : t('title');
  const desc = description || t('description');

  return (
    <div className={clsx(className, 'pt-[72px] bg-gray-950')} {...other}>
      <Head>
        <title>{titl}</title>
        <meta property="description" content={desc} />
        <meta property="og:title" content={titl} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={t('title')} />
        <meta property="og:locale" content={router.locale} />
        <meta property="og:image" content={'https://swapscanner.io/images/opengraph.jpg'} />
        <meta property="og:url" content={'https://swapscanner.io' + router.asPath} />
      </Head>

      {children}

      {/*
        NOTE: m.div needs to be loaded somewhere on every entrypoint pages.
        Otherwise:
        1. <LazyMotion strict features={loadFeatures}>
        2. motion@loadFeatures()
        3. motion@featureDefinitions updated
        4. motion@use-features/featureNames updated

        However motion@use-features/featureNames must run before motion@loadFeatures().

        Adding any framer component will make use-features to be called.
      */}
      <m.div />
    </div>
  );
};

export default Page;
