import { Menu } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { Gear } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { MenuTransition } from '@/components/transition';
import { Currency, CURRENCY_LIST, useCurrencyContext } from '@/context/currency';

import type { Dispatch, SetStateAction } from 'react';

interface SettingsMenuButtonV2Props {
  setIsSideBarShow: Dispatch<SetStateAction<boolean>>;
}

const CURRENCY_ORDER: Currency[] = ['USD_COINGECKO', 'KRW_COINGECKO', 'KRW_BITHUMB'];

export default function SettingsMenuButtonV2({ setIsSideBarShow }: SettingsMenuButtonV2Props) {
  const router = useRouter();
  const { currency, setCurrency, forex } = useCurrencyContext();
  const { t } = useTranslation('common');

  const currentLocale = router.locale;

  return (
    <Menu as="div" className="z-30 relative inline-flex text-left">
      <Menu.Button className="hover:opacity-70" onClick={() => setIsSideBarShow(false)}>
        <Gear className="w-6 h-6 outline-none text-gray-200" weight="fill" />
      </Menu.Button>

      <MenuTransition>
        <Menu.Items
          className={clsx(
            'absolute top-full translate-y-2 right-0 mt-2 w-56 origin-top-right',
            'divide-y rounded-md shadow-xl ring-1 focus-visible:outline-none ring-slate-50/5',
            'divide-slate-500 bg-dark-card-color',
          )}
          style={{ zIndex: 10 }}
        >
          <div className="px-2.5 py-2">
            <p className="px-2 mb-1 text-slate-300">{t('Language')}</p>
            {router.locales?.map((lang) => (
              <Menu.Item key={lang}>
                {({ active }) => (
                  <button
                    className={clsx(
                      'flex w-full items-center rounded-md px-2 py-2 text-sm justify-between',
                      {
                        'text-slate-300': active && lang !== currentLocale,
                        'text-slate-400': !active,
                        'text-slate-100 font-medium': lang === currentLocale,
                      },
                    )}
                    onClick={() => {
                      document.cookie = `NEXT_LOCALE=${lang}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
                      router.push(router.asPath, router.asPath, {
                        locale: lang,
                        scroll: false,
                      });
                    }}
                  >
                    <span>{t(lang)}</span>
                    <CheckIcon
                      className={clsx('h-5 w-5 text-slate-400', {
                        hidden: lang !== currentLocale,
                      })}
                    />
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>

          <div className="px-2.5 py-2">
            <p className="px-2 mb-1 text-slate-300">{t('Currency')}</p>
            {CURRENCY_LIST.sort(
              (a, b) => CURRENCY_ORDER.indexOf(a) - CURRENCY_ORDER.indexOf(b),
            ).map((cur) => (
              <Menu.Item key={cur}>
                {({ active }) => (
                  <button
                    className={clsx(
                      'flex w-full items-center rounded-md px-2 py-2 text-sm justify-between text-left',
                      {
                        'text-slate-300': active && cur !== currency,
                        'text-slate-400': !active,
                        'text-slate-100 font-medium': cur === currency,
                        'cursor-not-allowed opacity-50': !forex || !forex[cur],
                      },
                    )}
                    disabled={!forex || !forex[cur]}
                    onClick={() => setCurrency(cur)}
                  >
                    <div className="flex flex-col items-start">
                      <span>{t(cur.slice(0, 3))}</span>
                      <span className="text-[13px]">
                        {t(
                          ['USD', 'KRW'].includes(cur)
                            ? 'main_description'
                            : cur.includes('COINGECKO')
                            ? 'coingecko_description'
                            : 'bithumb_description',
                        )}
                      </span>
                    </div>

                    <CheckIcon
                      className={clsx('h-5 w-5 text-slate-400', {
                        hidden: cur !== currency,
                      })}
                    />
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </MenuTransition>
    </Menu>
  );
}
