import Link from 'next/link';

import { SCNRIcon, SwapscannerLogo } from '@/components/vector';

export default function Logo({ onClickHandler }: { onClickHandler?: () => void }) {
  return (
    <Link href="/">
      <a className="rounded-md p-1 hover:opacity-70" onClick={onClickHandler && onClickHandler}>
        <SwapscannerLogo className="h-6 w-auto hidden sm:block" />
        <SCNRIcon className="h-8 w-auto sm:hidden" />
      </a>
    </Link>
  );
}
