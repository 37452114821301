import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { List, X } from '@phosphor-icons/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import menuConfig from '@/components/core/MenuConfigV3';
import MenuMobileV3 from '@/components/core/MenuMobileV3';
import SettingsMenuButtonV2 from '@/components/core/SettingsMenuButtonV2';
import Logo from '@/components/layout/Logo';
import MultichartButton from '@/components/pro-swap/MultichartButton';
import TokenRateV2 from '@/components/TokenRateV2';
import WalletConnectorV2 from '@/components/WalletConnectorV2';
import { SCNR_ADDRESS } from '@/defines/token-address';
import { useWindowSize } from '@/hooks/use-window-size';

const CHART_PATHS = ['/pro/swap', '/pro/chart'];

export const HEADER_HEIGHT = 72;

export const sideBarHandler = (close?: boolean) => {
  const sideBar = document.getElementById('side-bar');
  if (!sideBar) return;

  sideBar.style.pointerEvents = close
    ? 'none'
    : sideBar.style.pointerEvents === 'none'
    ? 'auto'
    : 'none';

  sideBar.style.opacity = close ? '0' : sideBar.style.opacity === '0' ? '1' : '0';
};

export default function MainHeaderV3() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const pathName = useMemo(() => router.pathname, [router.pathname]);

  const [hoveredMenu, setHoveredMenu] = useState('');
  const [isSideBarShow, setIsSideBarShow] = useState(false);

  const closeSideBar = useCallback(() => {
    sideBarHandler(true);
    setIsSideBarShow(false);
  }, []);

  const size = useWindowSize();
  useEffect(() => {
    const sideBar = document.getElementById('side-bar');
    if (!sideBar) return;

    const isClosed = sideBar.style.opacity === '0';
    if (isClosed) return;

    closeSideBar();
  }, [size, closeSideBar]);

  useEffect(() => closeSideBar(), [router.pathname, closeSideBar]);

  return (
    <header
      className="w-full fixed z-20 top-0 backdrop-blur-[6px] flex flex-row justify-between items-center px-6 lg:px-10 bg-gray-900 shadow-md"
      style={{ height: HEADER_HEIGHT }}
      onMouseLeave={() => setHoveredMenu('')}
    >
      <div className="flex items-center w-full justify-between">
        <Logo onClickHandler={closeSideBar} />

        <div className="flex items-center space-x-5">
          <div className="hidden md:flex flex-row space-x-5 items-center">
            {menuConfig.map((menu) =>
              menu.subMenu ? (
                <div key={menu.title}>
                  <button
                    className={clsx(
                      'flex items-center space-x-1 font-semibold text-sm px-3 py-2 rounded-lg text-white hover:bg-gray-800',
                      {
                        'cursor-default': menu.subMenu,
                      },
                    )}
                    onMouseOver={() => setHoveredMenu(menu.title)}
                  >
                    <span>{menu.title}</span>
                    <ChevronDownIcon
                      className={clsx('w-3 h-3', {
                        'rotate-180': hoveredMenu === menu.title,
                      })}
                    />
                  </button>

                  <Transition
                    show={hoveredMenu === menu.title}
                    enter="transition duration-200 ease-in-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave=""
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="absolute -translate-x-5 translate-y-4 flex flex-col space-y-2 items-start p-2 rounded-xl shadow-[0_2px_16px_0px_rgba(0,0,0,0.32)] max-w-[240px] w-full bg-gray-900 text-white"
                      onMouseOver={() => setHoveredMenu(menu.title)}
                    >
                      {menu.subMenu.map((subMenu) => {
                        const inPreparation = 'inPreparation' in subMenu && !!subMenu.inPreparation;

                        return (
                          <div key={subMenu.title} className="relative w-full">
                            <Link
                              passHref
                              href={{
                                pathname: subMenu.path,
                              }}
                              prefetch={false}
                            >
                              <a
                                className={clsx(
                                  'flex items-center space-x-4 text-white font-semibold text-sm p-2 w-full rounded-xl',
                                  {
                                    'hover:bg-gray-800': !inPreparation,
                                    'cursor-not-allowed': inPreparation,
                                  },
                                )}
                                onClick={(e) => {
                                  if (inPreparation) return e.preventDefault();

                                  setHoveredMenu('');
                                }}
                              >
                                {subMenu.icon}
                                <div className="flex flex-col items-start space-y-1">
                                  <div className="flex items-center space-x-2">
                                    <span className="text-[15px]">{subMenu.title}</span>
                                    {subMenu.suffix && (
                                      <span
                                        className={clsx(
                                          'rounded leading-3 px-1 py-0.5 font-semibold text-[10px]',
                                          subMenu.suffix.className,
                                        )}
                                      >
                                        {subMenu.suffix.text}
                                      </span>
                                    )}
                                  </div>
                                  <span className="text-xs text-gray-400">
                                    {t(subMenu.description)}
                                  </span>
                                </div>
                              </a>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </Transition>
                </div>
              ) : (
                <Link key={menu.title} passHref href={{ pathname: menu.path }} prefetch={false}>
                  <a
                    className="hidden md:flex flex-row items-center space-x-1 font-semibold text-sm px-3 py-2 rounded-lg text-white hover:bg-gray-800"
                    onMouseOver={() => setHoveredMenu('')}
                  >
                    <span>{menu.title}</span>
                  </a>
                </Link>
              ),
            )}
          </div>

          <div className="flex flex-row items-center space-x-4 md:space-x-6">
            <div className="flex flex-row items-center space-x-1">
              {/* The reason the width is fixed is because price changes affect the header layout. */}
              <div className="hidden xl:block text-center w-32">
                <TokenRateV2 address={SCNR_ADDRESS} />
              </div>

              <WalletConnectorV2 />
            </div>

            {CHART_PATHS.includes(pathName) && (
              <MultichartButton className="hidden lg:inline-flex" />
            )}

            <SettingsMenuButtonV2 setIsSideBarShow={setIsSideBarShow} />

            {typeof window !== undefined && (
              <button
                onClick={() => setIsSideBarShow(!isSideBarShow)}
                className="hover:opacity-70 md:hidden"
              >
                {!isSideBarShow ? (
                  <List className="w-6 h-6 outline-none text-gray-200" weight="bold" />
                ) : (
                  <X className="w-6 h-6 outline-none text-gray-200" weight="bold" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      <MenuMobileV3 isSideBarShow={isSideBarShow} onClickHandler={closeSideBar} />
    </header>
  );
}
