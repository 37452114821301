import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo } from 'react';

import WalletIcon from '@/components/vector/WalletIcon';
import { useDialog } from '@/context/dialog';
import { providerTypes, useWalletContext } from '@/context/wallet';
import { useAddressName } from '@/hooks/use-address-name';
import { useChecksumAddress } from '@/hooks/use-checksum-address';
import { useWindowSize } from '@/hooks/use-window-size';
import { dressAddress } from '@/utils/dress-address';

export default function WalletConnectorV2({
  className,
  size = 'base',
}: {
  className?: string;
  size?: 'base' | 'lg';
}) {
  const { openWalletConnectDialog } = useDialog();

  const { t } = useTranslation('common');
  const { width: ww } = useWindowSize();
  const isDesktop = useMemo(() => ww > 900, [ww]);

  const { wallet, walletNickname, setWalletNickname } = useWalletContext();

  const { checksumAddress } = useChecksumAddress(wallet?.account ?? null);

  const { data: addressName } = useAddressName({ address: checksumAddress });

  useEffect(() => {
    setWalletNickname(addressName?.name ?? null);
  }, [addressName, setWalletNickname]);

  const displayNameOrAddress = useMemo(() => {
    if (!checksumAddress) return isDesktop ? t('Connect Wallet') : t('Connect');
    return walletNickname || dressAddress(checksumAddress);
  }, [isDesktop, t, checksumAddress, walletNickname]);

  const imgSrc = useMemo(() => {
    if (!wallet) return;

    if (wallet.type === 'walletConnect') {
      return (wallet as any).metadata.icon;
    }

    return providerTypes.filter(({ id }) => id === wallet.type)[0].icon;
  }, [wallet]);

  return (
    <button
      onClick={() => openWalletConnectDialog()}
      className={clsx(
        className,
        'flex flex-row items-center justify-center space-x-2 bg-teal-500 text-sm font-semibold px-3 rounded-lg hover:opacity-70',
        {
          'h-12': size === 'lg',
          'h-9': size === 'base',
          'max-w-[150px]': !!walletNickname && !!wallet?.account,
        },
      )}
    >
      {!wallet ? (
        <WalletIcon className="w-4 text-white" />
      ) : (
        <img className="w-4 h-4 rounded" src={imgSrc} alt={imgSrc} />
      )}

      <p
        className={clsx('whitespace-nowrap text-white', {
          truncate: !!walletNickname,
        })}
      >
        {displayNameOrAddress}
      </p>
    </button>
  );
}
